import * as x509 from "@peculiar/x509";
import authService from './AuthService'

export interface DeploymentContainerStatusJsonData {
  alive: boolean
  last_failure_reason?: string
  num_restarts: number
  create_time?: string
  last_start_time?: string
  last_exit_time?: string
  container_id: string
  container_name?: string
}

export interface DeploymentStatusJsonData {
  actor_id: string
  actor_name: string
  container_repo: string
  container_version: string
  container_status?: DeploymentContainerStatusJsonData
}

export interface DeploymentStatusesJsonData {
  host: string
  statuses: DeploymentStatusJsonData[]
  update_time: string
}

export interface FactoryRecord {
  id: string
}

export interface CertificateRecordIntermediateData {
  cert: string
  display_name: string
}

export interface CertificateRecordExportData {
  subject: string
  pubkey_algorithm_name: string
  pubkey_algorithm_additional_info: string
  signature_algorithm_hash: string
  creation: Date
  expiration: Date
  parent: string
}

export interface CertificateRecords {
  end_entities: Record<string, CertificateRecordExportData>
  intermediates: Record<string, CertificateRecordExportData>
  root: Record<string, CertificateRecordExportData>
}

class MeshApi {
  async getDeployments(): Promise<DeploymentStatusesJsonData[]> {
    let tokens = authService.getTokens()
    let urlBase = process.env.REACT_APP_MESH_API_URL
    if (!tokens) {
      throw new Error('No tokens available')
    }
    let data = await fetch(`${urlBase}/entities/factory`, {
      headers: {
        Authorization: `Bearer ${tokens.idToken}`,
      },
    })
    let factory_records = (await data.json()) as FactoryRecord[]
    let id = ''
    if (factory_records.length === 0) {
      let createData = await fetch(`${urlBase}/entities/factory`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${tokens.idToken}`,
        },
      })
      let createResponse = (await createData.json()) as FactoryRecord
      id = createResponse.id
    } else {
      id = factory_records[0].id
    }
    let getData = await fetch(`${urlBase}/entities/factory/${id}/deployment_status`, {
      headers: {
        Authorization: `Bearer ${tokens.idToken}`,
      },
    })
    return (await getData.json()) as DeploymentStatusesJsonData[]
  }

  private static subjectName(certificate: x509.X509Certificate): string {
    return certificate.subjectName.getField("CN")[0];
  }

  private static issuerName(certificate: x509.X509Certificate): string {
    return certificate.issuerName.getField("CN")[0];
  }

  private static certificateExportData(certificate: x509.X509Certificate, parent: string): CertificateRecordExportData {
    let additional_info = '';
    if ('namedCurve' in certificate.publicKey.algorithm) {
      additional_info = certificate.publicKey.algorithm.namedCurve as string;
    } else if ('modulusLength' in certificate.publicKey.algorithm) {
      additional_info = (certificate.publicKey.algorithm.modulusLength as number).toString();
    }
    return {
      subject: MeshApi.subjectName(certificate),
      pubkey_algorithm_name: certificate.publicKey.algorithm.name,
      pubkey_algorithm_additional_info: additional_info,
      signature_algorithm_hash: certificate.signatureAlgorithm.hash.name,
      creation: certificate.notBefore,
      expiration: certificate.notAfter,
      parent: parent,
    };
  }

  static readonly CERTIFICATE_PEM_SUFFIX: string = "-----END CERTIFICATE-----";

  async getCertificates(): Promise<CertificateRecords> {
    let tokens = authService.getTokens()
    let urlBase = process.env.REACT_APP_MESH_API_URL
    if (!tokens) {
      throw new Error('No tokens available')
    }
    let data = await fetch(`${urlBase}/entities/factory`, {
      headers: {
        Authorization: `Bearer ${tokens.idToken}`,
      },
    })
    let factory_records = (await data.json()) as FactoryRecord[]
    let id = ''
    if (factory_records.length === 0) {
      let createData = await fetch(`${urlBase}/entities/factory`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${tokens.idToken}`,
        },
      })
      let createResponse = (await createData.json()) as FactoryRecord
      id = createResponse.id
    } else {
      id = factory_records[0].id
    }
    let getData = await fetch(`${urlBase}/entities/factory/${id}/certificate_records`, {
      headers: {
        Authorization: `Bearer ${tokens.idToken}`,
      },
    })
    let records = (await getData.json()) as CertificateRecordIntermediateData[]
    let result = { end_entities: {}, intermediates: {}, root: {} } as CertificateRecords;
    records.forEach((record) => {
      let raw_components = record.cert.split(MeshApi.CERTIFICATE_PEM_SUFFIX);
      let components = raw_components.slice(0, -1); // remove the trailing empty component
      let certs: x509.X509Certificate[] = components.map((component) => {
        component = component + MeshApi.CERTIFICATE_PEM_SUFFIX;
        return new x509.X509Certificate(component);
      });
      let parent_name = MeshApi.issuerName(certs[0]);
      result.end_entities[record.display_name] = MeshApi.certificateExportData(certs[0], parent_name);
      for (let i = 1; i < certs.length; i++) {
        let subject_name = MeshApi.subjectName(certs[i]);
        let parent_name = MeshApi.issuerName(certs[i]);
        if (subject_name !== parent_name && !(subject_name in result.intermediates)) {
          result.intermediates[subject_name] = MeshApi.certificateExportData(certs[i], parent_name);
        } else if (subject_name === parent_name && !(subject_name in result.root)) {
          result.root[subject_name] = MeshApi.certificateExportData(certs[i], parent_name);
        }
      }
    });
    return result;
  }
}

const api = new MeshApi()
export default api
