import api, { CertificateRecordExportData, CertificateRecords } from './MeshApi'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import authService from './AuthService'
import { intlFormatDistance } from 'date-fns'

function displayCertificates(title: string, records: Record<string, CertificateRecordExportData>) {
  return (
    <div>
      <h3>{title}</h3>
      <table className="certificates-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Creation</th>
            <th>Expiration</th>
            <th>Parent</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(records).sort((key1, key2) => {
            let record1 = records[key1]
            let record2 = records[key2]
            return record1.parent.localeCompare(record2.parent) || key1.localeCompare(key2)
          }).map(key => (
            <tr key={key}>
              <td>{key}</td>
              <td>{records[key].pubkey_algorithm_name} {records[key].pubkey_algorithm_additional_info} with {records[key].signature_algorithm_hash}</td>
              <td>{intlFormatDistance(records[key].creation, new Date())}</td>
              <td>{intlFormatDistance(records[key].expiration, new Date())}</td>
              <td>{records[key].parent}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const Certificates = () => {
  const [data, setData] = useState<CertificateRecords | null>(null)
  const navigate = useNavigate()

  const handleLogout = () => {
    authService.logout()
    navigate('/')
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (data == null) {
          const data = await api.getCertificates()
          console.log(data)
          setData(data)
        }
      } catch (error) {
        console.log('Failed to fetch certificates:', error)
        authService.logout()
        navigate('/')
      }
    }

    fetchData()
  }, [data, navigate])
  if (data == null) {
    return (
      <div className="header">
        <p>Mesh Certificates</p>
        <button className="meshout-button" onClick={() => navigate("/deployments")}>
          Deployments
        </button>
        <div className="spinner"></div>
      </div>
    )
  }
  return (
    <div>
      <div className="header">
        <p>Mesh Certificates</p>
        <button className="meshout-button" onClick={() => navigate("/deployments")}>
          Deployments
        </button>
        <button className="meshout-button" onClick={handleLogout}>
          Meshout
        </button>
      </div>
      {displayCertificates('End-Entities', data.end_entities)}
      {displayCertificates('Intermediate', data.intermediates)}
      {displayCertificates('Root', data.root)}
    </div>
  )
}

export default Certificates
