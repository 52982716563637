import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Login from './Login'
import Certificates from './Certificates'
import Deployments from './Deployments'
import Callback from './Callback'

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" Component={Login} />
          <Route path="/deployments" Component={Deployments} />
          <Route path="/certificates" Component={Certificates} />
          <Route path="/callback" Component={Callback} />
        </Routes>
      </div>
    </Router>
  )
}

export default App
