import api, { DeploymentStatusesJsonData } from './MeshApi'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import authService from './AuthService'

const Deployments = () => {
  const [data, setData] = useState<DeploymentStatusesJsonData[] | null>(null)
  const navigate = useNavigate()

  const handleLogout = () => {
    authService.logout()
    navigate('/')
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (data == null) {
          const data = await api.getDeployments()
          console.log(data)
          setData(data)
        }
      } catch (error) {
        console.log('Failed to fetch deployments:', error)
        authService.logout()
        navigate('/')
      }
    }

    fetchData()
  }, [data, navigate])

  if (data == null) {
    return (
      <div className="header">
        <p>Mesh Deployments</p>
        <button className="meshout-button" onClick={() => navigate("/certificates")}>
          Certificates
        </button>
        <div className="spinner"></div>
      </div>
    )
  }
  return (
    <div>
      <div className="header">
        <p>Mesh Deployments</p>
        <button className="meshout-button" onClick={() => navigate("/certificates")}>
          Certificates
        </button>
        <button className="meshout-button" onClick={handleLogout}>
          Meshout
        </button>
      </div>
      <div className="container">
        {data.map(host => (
          <div className="host-container" key={host.host}>
            <p>Host {host.host}</p>
            <div className="deployment-header">
              <p>Actor</p>
              <p>Version</p>
              <p>Status</p>
            </div>
            {host.statuses.map(deployment => (
              <div className="deployment-info" key={deployment.actor_id}>
                <p>{deployment.actor_name}</p>
                <p>{deployment.container_version}</p>
                <p>{deployment.container_status?.alive ? 'Alive' : 'Dead'}</p>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Deployments
