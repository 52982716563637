import authService from './AuthService'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Login = () => {
  const navigate = useNavigate()
  const [showLogin, setShowLogin] = useState(false)

  const handleLogin = () => {
    authService.login()
  }

  useEffect(() => {
    const checkLogin = async () => {
      await authService.loadConfig()
      const tokens = authService.getTokens()
      if (tokens) {
        navigate('/deployments')
      } else {
        setShowLogin(true)
      }
    }
    checkLogin()
  }, [navigate])

  return (
    <div>
      <div className="header">
        <p>Mesh Deployments</p>
        {showLogin && (
          <button className="meshin-button" onClick={handleLogin}>
            Meshin
          </button>
        )}
      </div>
    </div>
  )
}

export default Login
